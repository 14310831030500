.dm-frame-parent5 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.dm-frame-parent5 {
    flex: 1;
    border-radius: var(--br-31xl);
    background-color: var(--color-lavenderblush);
    overflow: hidden;
    padding: var(--padding-36xl) var(--padding-27xl);
    gap: var(--gap-6xs);
    min-width: 370px;
}

.dm-about-us-card {
    align-self: stretch;
    background-color: var(--color-white);
    padding: 20px 20px;
    min-width: 370px;
    text-align: left;
    /* font-size: var(--font-size-36xl); */
    color: var(--color-black);
    font-family: var(--font-roboto);
}

.dm-no-menu {
    text-align: center;
}

.dm-no-menu p {
    font-size: 24px;
    text-align: center;
}

@media (max-width: 768px) {
    .dm-frame-parent5 {
        padding: 15px 15px;

    }

}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
body {
  margin: 0;
  line-height: normal;
  overflow-x: hidden;
}
:root {
  /* fonts */
  --font-inter: Inter;
  --font-inherit: inherit;
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-5xl: 24px;
  --font-size-6xl: 25px;
  --font-size-9xl: 28px;
  --font-size-sm: 14px;
  --font-size-mid: 17px;
  --font-size-23xl: 42px;
  --font-size-xl: 20px;
  --font-size-42xl: 61px;
  --font-size-36xl: 55px;
  --font-size-smi: 13px;
  --font-size-11xl: 30px;
  --font-size-13xl: 32px;
  --font-size-3xl: 22px;
  --font-size-lgi: 19px;
  --font-size-lg: 18px;
  --font-size-17xl: 36px;
  --font-size-26xl: 45px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-indianred: #d26c6c;
  --color-whitesmoke-100: #f6f6f6;
  --color-whitesmoke-200: #f4f4f4;
  --color-whitesmoke-300: #eee;
  --color-mediumslateblue: #917ffb;
  --color-darkslategray: #383838;
  --color-gray-100: #828282;
  --color-gray-200: #1f1f1f;
  --color-gray-400: #0c0b0b;
  --color-gray-300: rgba(255, 255, 255, 0);
  --color-lavenderblush: #fff0f4;

  /* Gaps */
  --gap-12xs: 1px;
  --gap-6xl: 25px;
  --gap-55xl: 74px;
  --gap-21xl: 40px;
  --gap-7xs: 6px;
  --gap-sm: 14px;
  --gap-2xs: 11px;
  --gap-4xs: 9px;
  --gap-3xs: 10px;
  --gap-5xs: 8px;
  --gap-149xl: 168px;
  --gap-mini: 15px;
  --gap-xl: 20px;
  --gap-lgi: 19px;
  --gap-27xl: 46px;
  --gap-mid: 17px;
  --gap-2xl: 21px;
  --gap-xs: 12px;
  --gap-35xl: 54px;
  --gap-11xl: 30px;
  --gap-6xs: 7px;
  --gap-584xl: 603px;
  --gap-smi: 13px;

  /* Paddings */
  --padding-11xl: 30px;
  --padding-66xl: 85px;
  --padding-70xl: 89px;
  --padding-3xs: 10px;
  --padding-xl: 20px;
  --padding-lgi: 19px;
  --padding-2xs: 11px;
  --padding-10xs: 3px;
  --padding-16xl: 35px;
  --padding-131xl: 150px;
  --padding-7xl: 26px;
  --padding-6xl: 25px;
  --padding-5xl: 24px;
  --padding-sm: 14px;
  --padding-10xl: 29px;
  --padding-8xl: 27px;
  --padding-36xl: 55px;
  --padding-27xl: 46px;
  --padding-lg: 18px;
  --padding-12xs: 1px;
  --padding-18xl: 37px;
  --padding-65xl: 84px;
  --padding-9xl: 28px;
  --padding-6xs: 7px;
  --padding-3xl: 22px;
  --padding-5xs: 8px;
  --padding-61xl: 80px;
  --padding-xs: 12px;
  --padding-62xl: 81px;
  --padding-17xl: 36px;
  --padding-67xl: 86px;
  --padding-smi: 13px;
  --padding-4xs: 9px;
  --padding-base: 16px;
  --padding-4xl: 23px;
  --padding-398xl: 417px;
  --padding-12xl: 31px;
  --padding-8xs: 5px;
  --padding-11xs: 2px;

  /* border radiuses */
  --br-14xl: 33px;
  --br-xl: 20px;
  --br-5xs: 8px;
  --br-7xl: 26px;
  --br-31xl: 50px;
  --br-xs: 12px;
  --br-sm: 14px;
  --br-10xl: 29px;
  --br-16xl: 35px;
  --br-3xs: 10px;
  --br-11xl: 30px;
  --br-mini: 15px;
}

.screenshot-2023-08-13-at-16101 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-14xl);
  max-width: 100%;
  overflow: hidden;
  height: 232px;
  flex-shrink: 0;
  object-fit: cover;
}

.screenshot-2023-08-13-at-1610-wrapper {
  width: 340px;
  height: 224px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact-us-on {
  position: relative;
}

.contact-us-on-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}

.frame-child5,
.frame-child6 {
  position: relative;
  width: 23.62px;
  height: 24.49px;
}

.frame-child6 {
  width: 24px;
  height: 24px;
}

.follow-us-on-parent,
.frame-parent10 {
  width: 203px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
}

.frame-parent10 {
  width: 239px;
  height: 205px;
}

.graan-voor-visch {
  margin: 0;
}

.graan-voor-visch-container {
  position: relative;
  font-size: 20px;
  color: var(--color-white);
}

.address-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.opening-hours {
  position: relative;
  text-transform: capitalize;
}

.frame-parent11,
.opening-hours-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.opening-hours-parent {
  gap: var(--gap-7xs);
  font-size: 20px;
}

.frame-parent11 {
  height: 209px;
  gap: var(--gap-21xl);
}

.frame-parent9,
.frame-wrapper10 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.frame-parent9 {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-55xl);
}

.frame-wrapper10 {
  align-self: stretch;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-width: 350px;
}

.frame-parent8,
.frame-wrapper9 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.frame-parent8 {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 var(--padding-70xl);
  justify-content: center;
  gap: var(--gap-6xl);
}

.footer,
.footer-inner {
  align-self: stretch;
  display: flex;
}

.footer-inner {
  background-color: var(--color-black);
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--padding-11xl) var(--padding-66xl);
  align-items: flex-start;
  justify-content: center;
}

.footer {
  background-color: var(--color-white);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 370px;
  text-align: left;
  font-size: var(--font-size-9xl);
  color: var(--color-indianred);
  font-family: var(--font-inter);
}

@media only screen and (max-width: 768px) {
  .frame-parent8 {
    padding: 0 0;
  }

  .frame-parent9 {
    justify-content: center;
  }

  .address-parent {
    align-items: center;
    text-align: center;
  }

  .opening-hours-parent {
    align-items: center;
    text-align: center;
  }
}
.abs-frame-icon {
  flex: 1;
  border-radius: var(--br-10xl);
  /* max-width: 100%; */
  overflow: hidden;
  height: 415px;
  object-fit: cover;
  min-width: 360px;
  width: 70%;
}
.abs-about-us-what {
  align-self: stretch;
  position: relative;
  font-size: 30px;
  text-align: center;
  /* font-weight: 100; */
}
.abs-indus-flavors-brings {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 160%;
  font-family: var(--font-inter);
  color: var(--color-gray-100);
  text-align: left;
}
.abs-about-us-what-we-do-parent,
.abs-frame-wrapper6,
.abs-frame-wrapper7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.abs-about-us-what-we-do-parent {
  align-self: stretch;
  /* height: 273px; */
  padding: 0 0 var(--padding-10xl) var(--padding-12xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
}
.abs-frame-wrapper6,
.abs-frame-wrapper7 {
  /* height: 328px; */
  min-width: 345px;
}
.abs-frame-wrapper7 {
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  height: 100%;
  /* max-width: 555px; */
}
.abs-frame-wrapper6 {
  align-self: stretch;
}
.abs-know-more {
  align-self: stretch;
  position: relative;
  line-height: 134.02%;
}
.abs-frame-parent6,
.abs-know-more-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.abs-know-more-wrapper {
  border-radius: var(--br-sm);
  background-color: var(--color-mediumslateblue);
  height: 70px;
  padding: 0 var(--padding-5xl) 0 var(--padding-lg);
  box-sizing: border-box;
  justify-content: center;
  opacity: 0.7;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}
.abs-frame-parent6 {
  flex: 1;
  justify-content: center;
  gap: var(--gap-mid);
  min-width: 350px;
}
.abs-about-us-card,
.abs-frame-parent5 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.abs-frame-parent5 {
  flex: 1;
  border-radius: var(--br-31xl);
  background-color: var(--color-lavenderblush);
  overflow: hidden;
  padding: var(--padding-36xl) var(--padding-27xl);
  gap: var(--gap-6xs);
  min-width: 370px;
}
.abs-about-us-card {
  align-self: stretch;
  background-color: var(--color-white);
  padding: var(--padding-11xl) var(--padding-66xl);
  min-width: 370px;
  text-align: left;
  /* font-size: var(--font-size-36xl); */
  color: var(--color-black);
  font-family: var(--font-roboto);
}

br::before {
  content: "\A";
  display: block;
  margin-bottom: 10px; /* Adjust this value to increase/decrease spacing */
}

br::after {
  content: "\A";
  display: block;
  margin-bottom: 10px; /* Adjust this value to increase/decrease spacing */
}

@media (max-width: 767px) {
  .abs-frame-parent5 {
    padding: var(--padding-3xs) var(--padding-3xs);
  }
  .abs-about-us-card {
    padding: var(--padding-3xs);
  }
  .abs-about-us-what {
    font-weight: 100;
    text-align: center;
  }

  .abs-indus-flavors-brings {
    font-size: 16px;
  }
  /* .abs-frame-parent6 {
    min-width: 350px;
  }
  .abs-frame-wrapper7 {
    max-width: 555px;
  }
  .abs-frame-wrapper6 {
    min-width: 345px;
  }
  .abs-about-us-what-we-do-parent {
    padding: 0 0 var(--padding-10xl) var(--padding-12xs);
  }
  .abs-frame-wrapper6,
  .abs-frame-wrapper7 {
    height: 328px;
  }
  .abs-frame-wrapper7 {
    width: 100%;
  }
  .abs-frame-parent6 {
    min-width: 350px;
  }
  .abs-about-us-what-we-do-parent {
    height: 273px;
    padding: 0 0 var(--padding-10xl) var(--padding-12xs);
  }
  .abs-frame-wrapper6,
  .abs-frame-wrapper7 {
    height: 328px;
  }
  .abs-frame-wrapper7 {
    width: 100%;
  }
  .abs-frame-parent6 {
    min-width: 350px;
  }
  .abs-about-us-what-we-do-parent {
    height: 273px;
    padding: 0 0 var(--padding-10xl) var(--padding-12xs);
  }
  .abs-frame-wrapper6,
  .abs-frame-wrapper7 {
    height: 328px;
  }
  .abs-frame-wrapper7 {
    width: 100%;
  }
  .abs-frame-parent6 {
    min-width: 350px;
  }
  .abs-about-us-what-we-do-parent {
    height: 273px;
    padding: 0 0 var(--padding-10xl) var(--padding-12xs);
  }
  .abs-frame-wrapper6,
  .abs-frame-wrapper7 {
    height: 328px;
  }
  .abs-frame-wrapper7 {
    width: 100%;
  }
  .abs-frame-parent6 {
    min-width: 350px;
  } */
}

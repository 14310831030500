.frame-child7 {
  flex: 1;
  position: relative;
  border-radius: var(--br-xl);
  width: 50%;
  overflow: hidden;
  height: 712px;
  object-fit: cover;
}
.frame-wrapper13 {
  flex: 1;
  border-radius: var(--br-10xl);
  background-color: var(--color-gray-300);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--padding-11xs) 0px var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  min-width: 345px;
  width: 50%;
  padding-right: 15px;
}
.pineapple-cake {
  flex: 1;
  position: relative;
  font-weight: 900;
}
.pineapple-cake-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.div9 {
  flex: 1;
  position: relative;
  line-height: 134.02%;
  font-weight: 900;
}
.wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-17xl);
  color: var(--color-gray-400);
  font-family: var(--font-inter);
}
.frame-parent16 {
  align-self: stretch;
  height: 273px;
  display: flex;
  flex-direction: column;
  padding: 0px 0px var(--padding-10xl) var(--padding-12xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frame-wrapper26 {
  width: 100%;
  height: 134px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 345px;
  max-width: 555px;
}
.frame-wrapper25 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 345px;
}
.frame-wrapper22 {
  align-self: stretch;
  height: 134px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 345px;
}
.placeholder {
  position: relative;
  line-height: 134.02%;
}
.placeholder-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper29 {
  flex: 1;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  height: 65px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xl) var(--padding-398xl) var(--padding-4xl)
    var(--padding-12xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper28 {
  width: 593px;
  height: 76px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.placeholder-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px var(--padding-8xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.placeholder-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.frame-parent17 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-584xl);
}
.frame-wrapper27 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 345px;
  font-size: var(--font-size-xl);
  font-family: var(--font-inter);
}
.frame-wrapper21 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper20 {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper19 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.select-cake-size-wrapper {
  width: 607px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 345px;
  font-size: var(--font-size-xl);
}
.small4-6-people-wrapper {
  align-self: stretch;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--padding-4xs) var(--padding-base);
  align-items: flex-start;
  justify-content: center;
}
.frame-parent18 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.frame-wrapper36 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-lg);
  font-family: var(--font-inter);
}
.frame-parent14 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frame-wrapper16 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.order-online-now1 {
  align-self: stretch;
  position: relative;
  line-height: 134.02%;
}
.order-online-now-container {
  border-radius: var(--br-sm);
  background-color: var(--color-mediumslateblue);
  width: 302px;
  height: 82px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xs) var(--padding-5xl) var(--padding-smi);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--font-size-11xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}
.frame-parent13 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.frame-wrapper15 {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 350px;
}
.frame-wrapper14 {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-width: 345px;
}
.frame-parent12 {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  min-width: 345px;
  padding-right: 15px;
}
.frame-wrapper12 {
  flex: 1;
  border-radius: var(--br-11xl);
  background-color: var(--color-lavenderblush);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 370px;
}
.frame-wrapper11 {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 370px;
}
.detail-page-inner {
  align-self: stretch;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--padding-17xl) var(--padding-67xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  min-width: 370px;
  text-align: left;
  font-size: var(--font-size-26xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}

.input-form {
  width: 50%;
  height: 100%;
  border-radius: var(--br-sm);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray);
  padding: var(--padding-2xs) var(--padding-base);
  box-sizing: border-box;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-inter);
}

@media only screen and (max-width: 768px) {
  .input-form {
    width: 100%;
  }
  .frame-wrapper13 {
    width: 100%;
  }
  .frame-child7 {
    height: 300px;
  }
}

.frame-icon {
  flex: 1;
  border-radius: var(--br-10xl);
  max-width: 100%;
  overflow: hidden;
  height: 415px;
  object-fit: cover;
  min-width: 360px;
}
.about-us-what {
  align-self: stretch;
  position: relative;
  font-weight: 900;
}
.indus-flavors-brings {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 134.02%;
  font-family: var(--font-inter);
  color: var(--color-gray-100);
}
.about-us-what-we-do-parent,
.frame-wrapper6,
.frame-wrapper7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.about-us-what-we-do-parent {
  align-self: stretch;
  height: 100%;
  padding: 0 0 var(--padding-10xl) var(--padding-12xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
}
.frame-wrapper6,
.frame-wrapper7 {
  height: 100%;
  min-width: 345px;
}
.frame-wrapper7 {
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 555px;
}
.frame-wrapper6 {
  align-self: stretch;
}
.know-more {
  align-self: stretch;
  position: relative;
  line-height: 134.02%;
}
.frame-parent6,
.know-more-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.know-more-wrapper {
  border-radius: var(--br-sm);
  background-color: var(--color-mediumslateblue);
  height: 70px;
  padding: 0 var(--padding-5xl) 0 var(--padding-lg);
  box-sizing: border-box;
  justify-content: center;
  opacity: 0.7;
  font-size: var(--font-size-5xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}
.frame-parent6 {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-mid);
  min-width: 350px;
}
.about-us-card,
.frame-parent5 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}
.frame-parent5 {
  flex: 1;
  border-radius: var(--br-31xl);
  background-color: var(--color-lavenderblush);
  overflow: hidden;
  padding: var(--padding-36xl) var(--padding-27xl);
  gap: var(--gap-27xl);
  min-width: 370px;
}
.about-us-card {
  align-self: stretch;
  background-color: var(--color-white);
  padding: var(--padding-11xl) var(--padding-66xl);
  min-width: 370px;
  text-align: left;
  font-size: var(--font-size-36xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
@media (max-width: 767px) {
  .frame-parent5 {
    padding: var(--padding-3xs) var(--padding-3xs);
  }
  .about-us-card {
    padding: var(--padding-3xs);
  }
  .about-us-what {
    font-weight: 100;
    text-align: center;
  }

  .indus-flavors-brings {
    font-size: 16px;
  }
}

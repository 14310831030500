.div,
.frame-child {
  position: relative;
}
.frame-child {
  /* width: 62.38px; */
  height: 24.63px;
}
.header,
.parent {
  align-self: stretch;
  display: flex;
}
.parent {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  /* min-width: 350px; */
}
.header {
  background-color: var(--color-black);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-16xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-width: 370px;
  text-align: left;
  font-size: var(--font-size-lgi);
  color: var(--color-white);
  font-family: var(--font-inter);
}
.social-icon {
  width: 15px;
  height: 15px;
  margin: 0px 8px;
}

.lorem-ipsum-dolor5,
.lorem-ipsum-dolor6 {
  align-self: stretch;
  position: relative;
  line-height: 134.02%;
}
.lorem-ipsum-dolor5 {
  font-weight: 800;
  display: inline-block;
  min-width: 320px;
}
.lorem-ipsum-dolor6 {
  font-size: var(--font-size-5xl);
  color: var(--color-gray-100);
}
.home-child,
.lorem-ipsum-dolor-sit-amet-con-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.lorem-ipsum-dolor-sit-amet-con-parent {
  width: 100%;
  gap: var(--gap-6xl);
  min-width: 345px;
  max-width: 1141px;
}
.home-child {
  align-self: stretch;
  padding: var(--padding-16xl) var(--padding-131xl) var(--padding-7xl);
  text-align: center;
  font-size: var(--font-size-23xl);
  color: var(--color-gray-200);
  font-family: var(--font-inter);
}

.dedicated-to-serving,
.dedicated-to-serving1 {
  align-self: stretch;
  position: relative;
  font-weight: 900;
}
.dedicated-to-serving {
  display: inline-block;
  min-width: 320px;
}
.dedicated-to-serving1 {
  font-size: var(--font-size-xl);
}
.dedicated-to-serving-freshly-p-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  min-width: 345px;
  max-width: 1141px;
}
.center-tagline {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 60px var(--padding-131xl) var(--padding-12xs);
  align-items: flex-start;
  justify-content: center;
}
.home {
  position: relative;
  background-color: var(--color-white);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1px;
  min-width: 370px;
  text-align: center;
  font-size: var(--font-size-42xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}

.how-it-works1 {
  align-self: stretch;
  position: relative;
  font-weight: 900;
}
.lorem-ipsum-dolor {
  width: 100%;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 134.02%;
  font-family: var(--font-inter);
  display: inline-block;
  min-width: 345px;
  max-width: 845px;
}
.how-it-works-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.lorem-ipsum-dolor1 {
  margin: 0;
  padding-left: var(--padding-8xl);
}
.lorem-ipsum-dolor-container {
  flex: 1;
  position: relative;
  line-height: 134.02%;
  font-weight: 200;
}
.frame-wrapper8,
.question,
.question-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.question {
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  width: 420px;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  font-size: 20px;
}
.frame-wrapper8,
.question-parent {
  align-self: stretch;
}
.question-parent {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-xl);
  min-width: 345px;
}
.frame-wrapper8 {
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-10xl);
  text-align: left;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
}
.frame-parent7,
.how-it-works {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
}
.frame-parent7 {
  flex: 1;
  border-radius: var(--br-31xl);
  background-color: var(--color-whitesmoke-100);
  flex-direction: column;
  padding: var(--padding-6xl) var(--padding-5xl);
  align-items: center;
  gap: var(--gap-mini);
  min-width: 370px;
}
.how-it-works {
  align-self: stretch;
  background-color: var(--color-white);
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--padding-11xl) var(--padding-66xl);
  align-items: flex-start;
  min-width: 370px;
  text-align: center;
  font-size: var(--font-size-42xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
/* Media Query for Mobile Devices */
@media only screen and (max-width: 768px) {
  .question-parent {
    min-width: 270px;
  }
  .question {
    width: 368px;
    font-size: 15px;
  }
}

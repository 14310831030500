.fresh-cream-cakes {
  margin: 0;
}
.fresh-cream-cakes-container {
  flex: 1;
  position: relative;
  font-weight: 900;
}
.fresh-cream-cakes-for-every-oc-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.indulge-guilt-free-our {
  flex: 1;
  position: relative;
  line-height: 134.02%;
}
.indulge-guilt-free-our-light-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-13xl);
  color: var(--color-gray-100);
  font-family: var(--font-inter);
}
.frame-parent2,
.frame-wrapper2,
.frame-wrapper3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent2 {
  align-self: stretch;
  gap: var(--gap-sm);
}
.frame-wrapper2,
.frame-wrapper3 {
  min-width: 345px;
}
.frame-wrapper3 {
  width: 100%;
  max-width: 1141px;
}
.frame-wrapper2 {
  flex: 1;
  padding: var(--padding-6xs) 0 var(--padding-3xl);
  box-sizing: border-box;
}
.order-online-now {
  align-self: stretch;
  position: relative;
  line-height: 134.02%;
}
.order-online-now-wrapper {
  border-radius: var(--br-sm);
  background-color: var(--color-mediumslateblue);
  height: 91px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-5xl) 0 var(--padding-lg);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}
.frame-parent1,
.frame-wrapper1,
.frame-wrapper4 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper4 {
  flex-direction: column;
  min-width: 350px;
  font-size: var(--font-size-11xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}
.frame-parent1,
.frame-wrapper1 {
  flex-direction: row;
  flex-wrap: wrap;
}
.frame-parent1 {
  gap: var(--gap-mini);
}
.frame-wrapper1 {
  min-width: 345px;
}
.frame-item {
  flex: 1;
  border-radius: var(--br-xl);
  max-width: 100%;
  overflow: hidden;
  max-height: 300px;
  object-fit: cover;
}
.frame-parent3 {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gap-mini);
}
.frame-wrapper5 {
  flex: 1;
  border-radius: var(--br-10xl);
  background-color: var(--color-gray-300);
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  min-width: 360px;
}
.frame-container,
.frame-div,
.frame-wrapper5,
.home-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.frame-div {
  flex: 1;
  align-items: center;
  gap: var(--gap-35xl);
  min-width: 345px;
}
.frame-container,
.home-inner {
  box-sizing: border-box;
  align-items: flex-start;
}
.frame-container {
  flex: 1;
  border-radius: var(--br-16xl);
  background-color: var(--color-lavenderblush);
  padding: var(--padding-9xl) var(--padding-11xl);
  min-width: 360px;
}
.home-inner {
  align-self: stretch;
  background-color: var(--color-white);
  padding: var(--padding-18xl) var(--padding-65xl);
  min-width: 370px;
  text-align: left;
  font-size: var(--font-size-42xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}

/* Mobile devices breakpoint */
@media only screen and (max-width: 768px) {
  .fresh-cream-cakes-container {
    font-size: 30px;
  }
  .indulge-guilt-free-our {
    font-size: 20px;
  }
  .order-online-now-wrapper {
    height: 50px;
  }
  .order-online-now {
    font-size: 20px;
  }
  .home-inner {
    min-width: 360px;
    /* margin-left: 10px; */
    text-align: center;
  }
  .frame-wrapper4 {
    align-items: center;
  }
}

.cake-gallery {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--padding-11xl) 0;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-4xs);
  text-align: left;
  font-size: var(--font-size-mid);
  color: var(--color-black);
  font-family: var(--font-inter);
}

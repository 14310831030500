.details {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  min-width: 370px;
}

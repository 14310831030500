.component-child {
  position: relative;
  border-radius: var(--br-7xl);
  width: 323px;
  height: 338px;
  object-fit: cover;
}

.lorem-ipsum-dolor7 {
  position: relative;
  line-height: 134.02%;
  font-weight: 800;
  display: inline-block;
  width: 229px;
}

.div1,
.lorem-ipsum-dolor8,
.view-details {
  position: relative;
  line-height: 134.02%;
  display: inline-block;
  flex-shrink: 0;
}

.lorem-ipsum-dolor8 {
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray);
  width: 310px;
  height: 41px;
}

.div1,
.view-details {
  font-weight: 800;
  width: 62px;
  height: 22px;
}

.view-details {
  font-weight: 500;
  width: 83px;
  height: 18px;
}

.group,
.view-details-wrapper {
  display: flex;
  flex-direction: row;
}

.view-details-wrapper {
  border-radius: var(--br-5xs);
  background-color: var(--color-mediumslateblue);
  width: 91px;
  padding: var(--padding-2xs) var(--padding-10xs);
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: var(--font-size-sm);
  color: var(--color-white);
}

.group {
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-149xl);
  font-size: var(--font-size-6xl);
}

.lorem-ipsum-dolor-si-parent,
.rectangle-parent1 {
  background-color: var(--color-whitesmoke-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.lorem-ipsum-dolor-si-parent {
  width: 323px;
  gap: var(--gap-5xs);
}

.rectangle-parent1 {
  border-radius: var(--br-xl);
  padding: var(--padding-xl) var(--padding-xl) var(--padding-lgi);
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--font-size-mid);
  color: var(--color-black);
  font-family: var(--font-inter);
}
.screenshot-2023-08-13-at-1610 {
  position: relative;
  border-radius: var(--br-3xs);
  width: 49px;
  height: 43px;
  object-fit: cover;
  padding: 0 2px 0 8px;
}

.industfc {
  position: relative;
  font-weight: 800;
  display: inline-block;
  width: 94px;
  height: 22px;
  flex-shrink: 0;
}

.screenshot-2023-08-13-at-1610-parent {
  width: 148px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.frame-wrapper {
  height: 43px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.about-us {
  position: relative;
}

.about-us-wrapper {
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
}

.frame-group {
  width: 425px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: var(--font-size-3xl);
}

.frame-parent,
.nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.frame-parent {
  flex: 1;
  height: 47px;
  align-items: center;
  justify-content: space-between;
  min-width: 360px;
}

.nav {
  align-self: stretch;
  background-color: var(--color-whitesmoke-300);
  overflow: hidden;
  padding: var(--padding-5xs) var(--padding-61xl) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  min-width: 370px;
  text-align: left;
  font-size: var(--font-size-lgi);
  color: var(--color-black);
  font-family: var(--font-inter);
}

.hamburger {
  display: none;
  /* Initially hidden */
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 768px) {
  .hamburger {
    display: block;
    /* Show hamburger icon for mobile devices */
  }

  .frame-group {
    display: none;
    /* Initially hide the navigation links for mobile devices */
    flex-direction: column;
    /* Make it vertical */
    position: absolute;
    background-color: var(--color-whitesmoke-300);
    /* Background color */
    width: 100%;
    /* Full width */
    top: 104px;
    left: 0;
    /* Align to the left */
    z-index: 1000;
    /* Ensure it's above other elements */
    align-items: center;
  }

  .frame-group.open {
    display: flex;
    /* Show the navigation links when hamburger is clicked */
  }
}
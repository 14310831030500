.frame-child1,
.rectangle-icon {
  position: relative;
  border-radius: var(--br-7xl);
  width: 323px;
  height: 338px;
}

.rectangle-icon {
  object-fit: cover;
  z-index: 0;
}

.frame-child1 {
  z-index: 1;
  margin-left: -323px;
}

.daily-food-menu,
.get-home-cooked {
  position: relative;
  line-height: 134.02%;
  font-weight: 800;
  display: inline-block;
  width: 229px;
}

.get-home-cooked {
  font-size: var(--font-size-smi);
  font-weight: 600;
  color: var(--color-whitesmoke-100);
}

.daily-food-menu-parent,
.rectangle-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.daily-food-menu-parent {
  margin: 0 !important;
  position: absolute;
  top: 226px;
  left: 24px;
  flex-direction: column;
  gap: var(--gap-xs);
  z-index: 2;
}

.rectangle-parent {
  height: 338px;
  flex-direction: row;
  background-image: url(../images/cake-option.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.frame-child2,
.frame-child3,
.rectangle-parent {
  position: relative;
  border-radius: var(--br-7xl);
}

.frame-child2 {
  width: 331px;
  height: 346px;
  object-fit: cover;
  z-index: 0;
}

.frame-child3 {
  width: 323px;
  height: 338px;
  z-index: 1;
  margin-left: -512px;
}

.cakes-parent,
.party-orders-parent,
.rectangle-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.party-orders-parent {
  margin: 0 !important;
  position: absolute;
  top: 223px;
  left: 26px;
  flex-direction: column;
  gap: var(--gap-xs);
  z-index: 2;
}

.cakes-parent,
.rectangle-group {
  flex-direction: row;
  position: relative;
}

.cakes-parent {
  margin: 0 !important;
  position: absolute;
  top: 221px;
  left: 25px;
  flex-direction: column;
  gap: var(--gap-xs);
  z-index: 1;
}

.frame-parent4,
.options-card,
.rectangle-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.rectangle-container {
  border-radius: var(--br-7xl);
  justify-content: flex-start;
  position: relative;
  background-image: url(../images/home-about-us.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.frame-parent4,
.options-card {
  flex-wrap: wrap;
  justify-content: center;
  min-width: 360px;
}

.frame-parent4 {
  flex: 1;
  gap: var(--gap-2xl);
}

.options-card {
  width: 100%;
  background-color: var(--color-white);
  overflow: hidden;
  padding: var(--padding-11xl) 0;
  box-sizing: border-box;
  max-width: 1440px;
  text-align: left;
  font-size: var(--font-size-mid);
  color: var(--color-white);
  font-family: var(--font-inter);
}